import logo from './logo.svg';
import './App.css';
import Home from './Page/Home/home';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUS from './Page/About_US/about-as';
import Faq from './Page/Faq/faq';
import Contact from './Page/Contact/contact';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import Sales from './Page/Sales/sales';
import Rentals from './Page/Rentals/rentals';
import Detail from './Page/Detail/detail';
import CookiePolicy from './components/layout/Footer/Modal/CookiePolicy';
import PrivacyPolicy from './components/layout/Footer/Modal/Privacy_Policy';
import WebsiteTerms from './components/layout/Footer/Modal/Website_Terms';
import { useEffect } from 'react';
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path={"/"} element={<Home />}></Route>
        <Route path={"/about-us"} element={<AboutUS />}></Route>
        <Route path={"/faq"} element={<Faq />}></Route>
        <Route path={"/contact"} element={<Contact />}></Route>
        <Route path={"/sales"} element={<Sales type="Sales" />}></Route>
        <Route path={"/rentals"} element={<Rentals type="rentals" />}></Route>
        <Route path={"/detail/:id"} element={<Detail />}></Route>
        <Route path={"/cookie-policy"} element={<CookiePolicy />}></Route>
        <Route path={"/privacy-policy"} element={<PrivacyPolicy />}></Route>
        <Route path={"/website-terms"} element={<WebsiteTerms />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
