import Banner from "../../components/Home/Banner/banner"
import ContactUs from "../../components/Home/Contact_Us/Contact_Us"
import Inventory from "../../components/Home/Inventory/Inventory"
import WithUs from "../../components/Home/With_US/with_us"
import Layout from "../../components/layout/layout"

const Home = () => {
    return (
        <>
            <Layout activeTab="Home">
                <div className="container">
                    <Banner />
                    <Inventory />
                    <WithUs />
                    <ContactUs />
                </div>
            </Layout>
        </>
    )
}
export default Home