import FaqBanner from "../../components/Home/Faq/Faq_banner"
import FaqCards from "../../components/Home/FaqCards/FaqCards"
import Layout from "../../components/layout/layout"

const Faq = () => {
    return (
        <>
            <Layout activeTab="FAQ">
                <FaqBanner />
                <FaqCards />
            </Layout>
        </>
    )
}
export default Faq