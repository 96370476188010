import { useEffect } from "react"
import { Rentals_String, Sales_String } from "../../Utils/Constants"
import MachineList from "../../components/Sales/Machine_List/machine_list"
import Layout from "../../components/layout/layout"
import "./rentals.css"

const Rentals = ({type}) => {
    
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    },[])
    return (
        <>
            <Layout activeTab="Rentals">
                <div className="container">
                    <div className="sales_box">
                        <div className="sales_title">{Rentals_String.title}</div>
                        <div className="sales_dec">{Rentals_String.dec}</div>
                    </div>
                    <MachineList type={type}/>
                </div>
            </Layout>
        </>
    )
}
export default Rentals