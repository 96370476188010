import { useEffect, useState } from "react"
import "./searchKeywordMenu.css"

const SearchKeywordMenu = ({ backButton, assetList, AssetTypeList, handelSelectAssetTypeList, handelSelectAssetTypeListBackFun, assetCategorizationSummaryLoading, state }) => {
    const [KeyWord, setkeyWord] = useState("")
    const [selectOption, setSelectOption] = useState('All')
    const handleSearch = (event) => {
        setkeyWord(event.target.value);
    };

    const handleFilter = (data, type) => {
        if (type === "assetList") {
            const filterDate = data?.filter((item) =>
                item.AssetTypeName?.toLowerCase().includes(KeyWord?.toLowerCase())
            );

            return filterDate

        } else if (type === "Category") {
            const filterDate = data?.filter((item) =>
                item.CategoryName?.toLowerCase().includes(KeyWord?.toLowerCase())
            );

            return filterDate
        } else if (type === "Tier") {
            const filterDate = data?.filter((item) =>
                item?.[
                    `Tier${assetList?.tierCount}Name`
                ]?.toLowerCase().includes(KeyWord?.toLowerCase())
            );

            return filterDate
        }
    }

    useEffect(() => {
        if (state?.CategoryId) {
            setSelectOption(state?.CategoryId)
        }
    }, [state])

    return (
        <select
            value={selectOption}
            className="select-box"
            onChange={(e) => {
                setSelectOption(e.target.value);
                if (e.target.value === "All") {
                    handelSelectAssetTypeList(assetList?.selectAssetDetails, {
                        Type: "AssetList",
                    })
                } else {
                    const CategoryItem = handleFilter(assetList?.selectAssetDetails?.CategoryDetails, "Category")?.find((CategoryItem) => CategoryItem?.CategoryId == e.target.value)
                    handelSelectAssetTypeList(CategoryItem, {
                        Type: "CategoryDetails",
                    })
                }
            }
            }

        >
            {assetCategorizationSummaryLoading ? (
                <div
                    style={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {" "}
                    <div className="spinner-container">
                        <div className="loading-spinner"></div>
                    </div>
                </div>
            ) :
                <>
                    <option name="CategoryDetails"
                        value={"All"}
                    >
                        All
                    </option>
                    {

                        handleFilter(assetList?.selectAssetDetails?.CategoryDetails, "Category")?.map(
                            (CategoryItem) => {
                                return (
                                    <option name="CategoryDetails" value={CategoryItem?.CategoryId}> {CategoryItem?.CategoryName}
                                    </option>
                                );
                            }
                        )
                    }
                </>

            }

        </select >
    )
}
export default SearchKeywordMenu