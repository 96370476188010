import HonestImg from "../assets/img/HONEST.png"
import WinningImg from "../assets/img/WINNING.png"

export const ReCAPTCHA_sitekey = "6Ldp-mopAAAAACxk2mJHG_mUfAi6wqni9jAxBgNb";

export const header = [
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'Sales',
    path: '/sales'
  },
  {
    title: 'Rentals',
    path: '/rentals'
  },
  {
    title: 'Contact',
    path: '/contact'
  },
  {
    title: 'FAQ',
    path: '/faq'
  },
  {
    title: 'About Us',
    path: '/about-us'
  },
]

export const header_Strings = {
  Slogan: "Looking to dump dirt in Marietta GA ",
  dumpdirt: "dumpdirt.com",
  Call_US: "Call US (404) 859-7609",
  Credit_App: "Credit App",
  Ach_Form: "ACH Form"
}

export const Links = {
  dumpdirt: "https://www.dumpdirt.com/",
  Credit_App: "https://www.hyellc.com/_files/ugd/86f5a1_8fdd452bcfb44caf9b8fa4dac58277a5.pdf",
  Ach_Form: "https://www.hyellc.com/_files/ugd/86f5a1_bbd4bd663c0a4fe29449d32df41321d2.pdf",
  Number: "(404) 859-7609",
  Integrasoft: "https://heavyyellow-rental.integrasoft.net"
}

export const Dashboard_Strings = {
  Welcome: "WELCOME TO HEAVY YELLOW EQUIPMENT, LLC",
  Ultimate_Heavy: "The Ultimate Heavy Equipment Rental and Sales",
  Get_in_Touch: "Get in Touch"
}

export const Inventory_Strings = {
  Our_Inventory: "OUR  INVENTORY",
  Many_Options: "Many Options"
}

export const Why_US_String = {
  WHY_US: "WHY RENT WITH US?",
  Everything_You: "Everything You Want and More"
}

export const Why_US_Array = [
  {
    img: HonestImg,
    title: "ACCURATE AND HONEST",
    dec: "We firmly believe this is one of our strongest qualities when compared against other rental shops. Our team members are more than happy to answer any of your questions before, during, and after the rental process. Not satisfied? We’ll be sure to do everything in our power to get the job done right."
  },
  {
    img: WinningImg,
    title: "AWARD-WINNING",
    dec: "Driven by quality, we’ve been at it long enough to know how important it is to provide top notch rentals and real customer service. We’re there for clients whenever they need us. When you need help or guidance on which product to choose, trust the real pros. Come by our rental yard today."
  },
]

export const contact_us = {
  Contact_US: "CONTACT US",
  address_1: "80 Whitlock Place",
  address_2: "Suite 201",
  address_3: "Marietta, GA 30064",
  email: "jay@hyellc.com",
  number: "404-859-7609",
  IntegraRental: "IntegraRental",
  fb: "https://www.facebook.com/HYELLC"
}

export const FooterString = {
  Subscribe: "Subscribe",
  Copyright: "by Heavy Yellow Equipment, LLC. ",
  Privacy_Policy: "Privacy Policy",
  Website_Terms: "Website Terms and Conditions",
  Cookie_Policy: "Cookie Policy",
}

export const About_US_String = {
  About_US: "ABOUT US",
  Our_History: "Our History",
  About_US_String: "Heavy Yellow Equipment, LLC started in 2020 right before the COVID-19 pandemic. HYE was started by Jerry and Jay Brooks. They had just sold their land clearing and urban wood waste recycling company. Not wanting to slow down, they both decided that an equipment company would be their next adventure."
}

export const Faq_String = {
  title: "FREQUENTLY ASKED QUESTIONS",
  subTitle: "Your Help Center"
}

export const FAQ = [
  {
    title: "WHICH PAYMENT METHODS DO YOU ACCEPT?",
    subTitle: "We accept cash, check, ACH, and credit cards."
  },
  {
    title: "DO YOU OFFER DELIVERY?",
    subTitle: "Yes! Please contact us about our competitive delivery rates."
  },
  {
    title: "WHAT IS YOUR POLICY FOR DAMAGED PRODUCTS?",
    subTitle: "We will inspect equipment at time of deliver and pick up. If there are any damages due to operator error, the customer will have to repair."
  },
]

export const Sales_String = {
  title: "SALES INVENTORY",
  dec: "At Heavy Yellow Equipment, LLC, our equipment is maintained with OEM parts by our in-house staff with local dealer support. Our inventory is changing daily so please call us for our up to date inventory. If you have units that you want to sell we are always looking to increase our fleet. "
}

export const Rentals_String = {
  title: "WHAT WE OFFER",
  dec: "See What We Have  "
}