import { Link, useNavigate } from "react-router-dom"
import Modal from "react-responsive-modal";
import { FooterString, Links, contact_us, header } from "../../../Utils/Constants"
import logoImg from "../../../assets/img/logo.png"
import "./footer.css"
import { useState } from "react"
import CookiePolicy from "./Modal/CookiePolicy";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <>
            {/* <div className="footer-bg"> */}
            <div className="container">
                <div className="footer">
                    <div className="footer_grid">
                        <div className="footer_logo_grid">
                            <img src={logoImg} alt="" className="logo_ieda" />
                        </div>

                        {/* <div>
                                <a href={`tel:${contact_us.number}`} className="footer_contact_link"><div>{contact_us.number}</div></a>
                                <a href={`mailto:${contact_us.email}`} className="footer_contact_link"><div>{contact_us.email}</div></a>
                            </div> */}

                        <div>
                            <div className="footer_Address ContactUs_Address">{contact_us.address_1}</div>
                            <div className="footer_Address ContactUs_Address">{contact_us.address_2}</div>
                            <div className="footer_Address ContactUs_Address">{contact_us.address_3}</div>
                            <div className="footer_Address">
                                <a href={Links?.Integrasoft} target="_blank">
                                    {contact_us.IntegraRental}
                                </a>
                            </div>
                        </div>

                        <div>

                            <div className="footer_subscribe_box">
                                <input type="text" placeholder="Type your email address..." className="footer_input_box" />
                                <button
                                    className={"CONTACT_btn_Yellow_Color"
                                    }
                                >{FooterString.Subscribe}
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="Privacy_Policy_Box">
                        <div className="Privacy_Policy_Text">
                            <p
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                    navigate(`/privacy-policy`);
                                }}
                            >{FooterString.Privacy_Policy}</p>
                        </div>
                        <div className="Privacy_Policy_Text">
                            <p
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                    navigate(`/website-terms`);
                                }}
                            >{FooterString.Website_Terms}</p>
                        </div>
                        <div className="Privacy_Policy_Text">
                            <p
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                    navigate(`/cookie-policy`);
                                }}
                            >{FooterString.Cookie_Policy}</p>
                        </div>
                    </div>
                    <div className="Copyright">
                        ©{new Date().getFullYear()} {FooterString.Copyright}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer