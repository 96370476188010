import "./faqCards.css"
import { FAQ } from "../../../Utils/Constants"

const FaqCards = () => {
    return (
        <>
            <div className="container">
                <div className="Faq_Card">
                    {FAQ.map((item) => {
                        return (
                            <div className="FaqCard">
                                <div className="Faq_Title">{item.title}</div>
                                <div className="Faq_Sub_Title">{item.subTitle}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
export default FaqCards