import { useEffect } from "react";
import Layout from "../../layout";

const PrivacyPolicy = () => {
    useEffect(() => {
        const loadScript = async () => {
            try {
                const script = document.createElement("script");
                script.src = "https://app.termly.io/embed-policy.min.js";
                script.async = true;
                document.body.appendChild(script);
            } catch (error) {
                console.error("Error loading Termly script:", error);
            }
        };

        loadScript();

        return () => {
            
        };
    }, []);
    return (
        <>
            <Layout>
                <div className="container">
                    <div name="termly-embed" data-id="45ddecc8-69b9-449c-8a65-0be5c1c3c32b"></div>
                </div>
            </Layout>
        </>
    )
}
export default PrivacyPolicy