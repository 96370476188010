import { useNavigate } from 'react-router-dom';
import './machinesCard.css'
const MachinesCard = ({ item, title, img }) => {
    const navigate = useNavigate();
    const imgLogo = []
    return (
        <>
            <div className='MachinesCard_Box_Hover' style={{ background: '#f7f7f7',boxShadow: 'rgb(50, 50, 50) 0px 0px 4px 0px',borderRadius:"15px" }}>
                <div
                    className="MachinesCard_Box"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                        const encodeURICom = encodeURIComponent(
                            `${item.assetAdvertisementId
                            }-${item?.primary_image?.name.replaceAll(" ", "-")}`
                        );
                        navigate(`/sales`, { state: item });
                        window.location.reload();
                    }}
                    style={{
                        backgroundImage: `url("/machinesImg/${imgLogo.filter((logo) => logo === item.CategoryId)?.length > 0 ? item.CategoryId : 'logo'}.png")`,
                        backgroundSize: imgLogo.filter((logo) => logo === item.CategoryId)?.length > 0 ? "contain" : '40%',
                    }}
                >
                    <div className='MachinesCard_title'>{title}</div>
                </div>
            </div>
        </>
    )
}
export default MachinesCard