import { useEffect } from "react";
import Footer from "./Footer/footer";
import Header from "./Header/header";

const Layout = ({ children, activeTab }) => {
  return (
    <>
        <Header activeTab={activeTab} />
        {children}
        <Footer />
    </>
  );
};
export default Layout;
