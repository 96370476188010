import { useEffect, useState } from "react";
import { Inventory_Strings } from "../../../Utils/Constants"
import "./Inventory.css"
import { getAllMachinesList, postMachinesList } from "../../../services/machineService";
import { ApplicationId } from "../../../api/url";
import MachinesCard from "../MachinesCard/machinesCard";
const Inventory = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [machinesList, setMachinesList] = useState([]);
    const [images, setImages] = useState([]);
    const [machinesTypeList, setMachinesTypeList] = useState([])

    const GetMachinesList = () => {
        setIsLoading(true);
        let payload = {
            PageSize: 6,
            PageNumber: 1,
            IsFeatured: true,
            ApplicationId: ApplicationId,
        };
        postMachinesList(payload)
            .then((res) => {
                let showImgList = [];
                res?.data?.machines?.map?.((item, index) => {
                    showImgList[index] = {
                        ...showImgList[index],
                        image: item?.primary_image?.url,
                        // caption: `<div  class="bg-[#000] responsive_text_field" style="background: rgba(0,0,0,0.5); width: 90%; margin: auto; text-align:right; padding:0px 10px ">

                        //   </div>`,
                        caption: `
                                <div style="
                                    border-left: 7px solid #fff;
                                    padding-left: 10px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;"
                                >
                                    <div class="carousel_label"> ${item?.year}&nbsp;&nbsp;&nbsp;${item?.model?.number}&nbsp;&nbsp;&nbsp;${item?.model?.manufacturer?.name}</div>
                                    <div class="carousel_sub_label">${item?.meter_reading ? item.meter_reading : 'N/A'} Hours</div>
                                </div>
                            `,
                        assetAdvertisementId: item?.assetAdvertisementId,
                        name: item?.primary_image?.name.replaceAll(" ", "-"),
                    };
                });
                setImages(showImgList);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const GetMachinesTypeList = val => {
        getAllMachinesList()
            .then(res => {
                const WithoutAttachmentsList = res?.data?.filter(
                    item => item?.AssetTypeId !== 1,
                )
                setMachinesTypeList(WithoutAttachmentsList)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        GetMachinesTypeList()
    }, [])


    useEffect(() => {
        GetMachinesList();
        if (window.location.href.split("userID=")?.[1]) {
            localStorage.setItem("UserID", window.location.href.split("userID=")?.[1])
        }
    }, []);


    const GetRecentMachinesList = () => {
        // setIsLoading(true);
        let payload = {
            PageSize: 9,
            PageNumber: 1,
            IsLatestFirst: true,
            ApplicationId: ApplicationId,
        };
        postMachinesList(payload)
            .then((res) => {
                setMachinesList(res?.data);
                // setIsLoading(false);
            })
            .catch((err) => console.log(err));
        // setIsLoading(false);
    };

    useEffect(() => {
        GetRecentMachinesList();
    }, []);

    return (
        <>
            <div className="Inventory">
                <div className="Inventory_Heading">
                    {Inventory_Strings.Our_Inventory}
                </div>

                
                <div className="Inventory_Sub_Heading">
                    {Inventory_Strings.Many_Options}
                </div>

                <div className="machine_list">
                    <div className="Machines_Data_Lists">
                        {machinesTypeList?.[0]?.CategoryDetails.map((item) => {
                            return (
                                <MachinesCard item={item} title={item.CategoryName} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Inventory