import { useEffect } from "react"
import { Sales_String } from "../../Utils/Constants"
import MachineList from "../../components/Sales/Machine_List/machine_list"
import Layout from "../../components/layout/layout"
import "./sales.css"

const Sales = ({ type }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <>
            <Layout activeTab="Sales">
                <div className="container">
                    <div className="sales_box">
                        <div className="sales_title">{Sales_String.title}</div>
                        <div className="sales_dec">{Sales_String.dec}</div>
                    </div>
                    <MachineList type={type} />
                </div>
            </Layout>
        </>
    )
}
export default Sales