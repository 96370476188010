import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/layout/layout"
import "./detail.css"
import { useEffect, useState } from "react";
import { getMachineDetailsByID } from "../../services/machineService";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ImageGallery from "react-image-gallery";
import gmailIcon from "../../assets/img/gmail.png";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from "react-responsive-modal";
import ContactForm from "../../components/Contact-Form";

function currencyFormat(num) {
    return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Detail = (props) => {
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    const [searchModalIsOpen, setSearchIsOpen] = useState(false);
    const navigate = useNavigate();
    const getMachineDetails = () => {
        setIsLoading(true);
        const decode = decodeURIComponent(props?.match?.params?.id);
        let id = decode.split("-");
        getMachineDetailsByID(state?.assetAdvertisementId)
            .then((res) => {
                let showImgList = [];

                const primary_image_info = res?.data?.primary_image

                const res_img_info =  res?.data?.images.filter((item) => item?.url !== primary_image_info?.url)

                primary_image_info.order = -1
                
                res_img_info.push(primary_image_info)

                const sortedData = res_img_info.sort((a, b) => a?.order - b?.order)
                
                sortedData?.map?.((item, index) => {
                    showImgList[index] = {
                        ...showImgList[index],
                        image: item?.url,
                        caption: ``,
                        assetAdvertisementId: item?.assetAdvertisementId,
                        name: item?.primary_image?.name.replaceAll(" ", "-"),
                        original: item?.url,
                        thumbnail: item?.url,
                    };
                });
                setImages(showImgList);
                setData(res?.data);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const handleOnPressCall = () => {
        if (data?.dealer?.contact_user?.phone) {
            return `callto:${data?.dealer?.contact_user?.phone}`;
        } else if (data?.dealer?.contact_user?.email) {
            return `mailto:${data?.dealer?.contact_user?.email}`;
        } else {
            return "javascript:void(0)";
        }
    };

    const handelCategorizationBreadcrumb = (e, categorization) => {
        const categorizatioList = e;
        navigate(
            `/equipment`,
            {
                state: categorizatioList,
            });
    }

    const mailTemplate = () => {
        let mail = `mailto:?subject=Machine Details &body=%0D%0APlease find the equipment details as below%0D%0A
                        %0D%0ATitle%20:%20${data?.year > 0 && data?.year}${data?.manufacturer?.name && data?.manufacturer.name
            }${data?.model?.number && data?.model?.number}
                        %0D%0APrice%20:%20${data?.advertised_price?.currency?.symbol
            }${data?.advertised_price?.fractional}
                        %0D%0AManufacturer%20:%20${data ? data?.manufacturer?.name : "-"
            }
                        %0D%0AModel%20:%20${data ? data?.model?.number : "-"}
                        %0D%0AYear%20:%20 ${data ? data?.year : "-"}
                        %0D%0ACondition%20:%20${data?.meter_reading ? "Used" : "New"
            }
                        %0D%0AHours%20:%20${data ? data?.meter_reading : "-"}
                        %0D%0ALink%20:%20${window.location.href}
                        `;
        return mail;
    };

    const closeModal = () => {
        setSearchIsOpen(false)
    }

    const openModal = () => {
        setSearchIsOpen(true)
    }

    useEffect(() => {
        getMachineDetails();
    }, []);
    console.log("images", images)
    return (
        <>
            <Layout>
                <div className="container Detail ">
                    {
                        isLoading ?
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: "30px",
                                    height: "60vh",
                                    alignItems: "center"
                                }}
                            >
                                <div className="spinner-container">
                                    <div className="loading-spinner"></div>
                                </div>
                            </div>
                            :
                            <>
                                {data?.categorization?.asset_type &&
                                    <div className='Equipment_bird_crump'>
                                        {data?.categorization?.tier3?.name && (
                                            <div className='Equipment_bird_crump_title'
                                                onClick={() => {
                                                    handelCategorizationBreadcrumb(`${data?.categorization?.asset_type?.id},${data?.categorization?.category?.id
                                                        },${data?.categorization?.tier1?.id},${data?.categorization?.tier2?.id},${data?.categorization?.tier3?.id},${data?.categorization?.category?.name}`,
                                                        data?.categorization);
                                                }}
                                            >
                                                {" > "}
                                                {data?.categorization?.tier3?.name}
                                            </div>
                                        )}
                                    </div>
                                }
                                <div>
                                    <div className="Carousel-detail">

                                        <ImageGallery
                                            items={images}
                                            infinite={true}
                                            showPlayButton
                                            autoPlay={true}
                                            useBrowserFullscreen={false}
                                            originalWidth={CarouselStyle.width}
                                            originalHeight={CarouselStyle.height}
                                            slideInterval={5000}
                                        />
                                    </div>


                                    <div className="detail-box  grid grid-cols-2">
                                        <div className="mt-5">
                                            <label className="detail-box-title mt-5">Specifications</label>
                                            <div className="mt-4 grid specifications-grid-cols-2">
                                                <label className="font-bold">Title: </label>
                                                <label>
                                                    {data?.year > 0 && data?.year}{" "}
                                                    {data?.manufacturer?.name && data?.manufacturer.name}{" "}
                                                    {data?.model?.number && data?.model?.number}
                                                </label>
                                            </div>
                                            {state?.type === "Sales" ?
                                                <div className="mt-4 grid specifications-grid-cols-2">
                                                    <label className="font-bold">Price: </label>
                                                    <label>
                                                        {data?.advertised_price?.fractional != 0 ? (
                                                            <>
                                                                {data?.advertised_price?.currency?.symbol}{" "}
                                                                {currencyFormat(
                                                                    Number(data?.advertised_price?.fractional)
                                                                )}
                                                            </>
                                                        ) : (
                                                            <a
                                                                href={handleOnPressCall()}
                                                                className="Call_for_Pricing"
                                                            >
                                                                Call for Pricing
                                                            </a>
                                                        )}
                                                    </label>
                                                </div> : null}
                                            <div className="mt-4 grid specifications-grid-cols-2">
                                                <label className="font-bold">Manufacturer: </label>
                                                <label>{data !== null ? data?.manufacturer?.name : "-"}</label>
                                            </div>
                                            <div className="mt-4 grid specifications-grid-cols-2">
                                                <label className="font-bold">Model: </label>
                                                <label>{data !== null ? data?.model?.number : "-"}</label>
                                            </div>
                                            <div className="mt-4 grid specifications-grid-cols-2">
                                                <label className="font-bold">Year: </label>
                                                <label>{data !== null ? data?.year : "-"}</label>
                                            </div>
                                            <div className="mt-4 grid specifications-grid-cols-2">
                                                <label className="font-bold">Condition: </label>
                                                {data?.meter_reading !== null ? <label>Used</label> : <label>New</label>}
                                            </div>
                                            <div className="mt-4 grid specifications-grid-cols-2">
                                                <label className="font-bold">Hours: </label>
                                                <label>{data !== null ? data?.meter_reading : "-"}</label>
                                            </div>
                                            <div className="mt-5">
                                                <label className="detail-box-title">Description </label>
                                            </div>
                                            {data?.configuration !== null ? <div
                                                dangerouslySetInnerHTML={{
                                                    __html: data?.configuration,
                                                }}
                                                className="mt-5 htmlTable"
                                            ></div>
                                                : "-"
                                            }
                                        </div>
                                        <div className="mt-5 share-box">
                                            <button
                                                className="get-more-info-btn"
                                                onClick={openModal}
                                            >
                                                Get Info
                                            </button>
                                            {/* <div className="share-text">
                                                Share
                                            </div> */}

                                            <a href={mailTemplate()} className="share-icon">
                                                <img
                                                    src={gmailIcon}
                                                    alt=""
                                                    className="cursor-pointer"
                                                    width={"100%"}
                                                />
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                    <Modal styles={{
                        modal: {
                            padding: '0px'
                        }
                    }} open={searchModalIsOpen} onClose={closeModal} center showCloseIcon={false}
                    >
                        <ContactForm handleInventoryContactMenu={`${data.assetAdvertisementId}-${data?.year || ''
                            }-${data?.manufacturer?.name}-${data?.model?.number
                            }`} />
                    </Modal>
                </div>
            </Layout>
        </>
    )
}

const CarouselStyle = {
    width: "1080px",
    height: "600px",
    thumbnailWidth: "100px",
    captionStyle: {
        display: "none",
    },
    layout: {
        textAlign: "center",
        maxWidth: "1080px",
        maxHeight: "100%",
        minHeight: "100%",
        margin: "50px auto 90px auto",
    },
};

export default Detail