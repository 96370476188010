import { useNavigate } from 'react-router-dom';
import ComingSoonImg from '../../assets/img/coming_soon_img.jpg'
import './equipmentCard.css'
import { currencyFormat } from '../../Utils/helper';
import Modal from 'react-responsive-modal';
import ContactForm from '../Contact-Form';
import { useState } from 'react';

const EquipmentCard = ({ machineData, type }) => {
    const navigate = useNavigate();
    const [searchModalIsOpen, setSearchIsOpen] = useState(false);
    const handleOnPressCall = () => {
        if (machineData?.dealer?.contact_user?.phone) {
            return `callto:${machineData?.dealer?.contact_user?.phone}`
        } else if (machineData?.dealer?.contact_user?.email) {
            return `mailto:${machineData?.dealer?.contact_user?.email}`
        } else {
            return 'javascript:void(0)'
        }
    }

    const navigateCard = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        const encodeURICom = encodeURIComponent(
            `${machineData.assetAdvertisementId
            }-${machineData?.primary_image?.name.replaceAll(" ", "-")}`
        );
        machineData = {
            ...machineData,
            type
        }
        navigate(`/detail/${encodeURICom}`, { state: machineData });
        window.location.reload();
    }

    const closeModal = () => {
        setSearchIsOpen(false)
    }

    const openModal = () => {
        setSearchIsOpen(true)
    }

    return (
        <>
            <div className="card">
                <div
                    className="cursor-pointer"
                    onClick={navigateCard}
                >
                    <img
                        className="customImage"
                        src={
                            machineData?.primary_image
                                ? machineData.primary_image.url
                                : ComingSoonImg
                        }
                        alt=""
                    />
                </div>

                <div id="description" className="cardDescription">
                    <div
                        className="cursor-pointer"
                        onClick={navigateCard}
                    >
                        <h2 className="machine-data-title">
                            {machineData?.year > 0 && machineData.year}{' '}
                            {machineData?.manufacturer?.name && machineData.manufacturer.name}{' '}
                            {machineData?.model?.number && machineData.model.number}
                        </h2>
                    </div>
                    <div>
                        {type === "Sales" ?
                            machineData?.advertised_price?.fractional != 0 ? (
                                <p className="machine-data-price">
                                    {machineData?.advertised_price?.currency?.symbol}{' '}
                                    {currencyFormat(
                                        Number(machineData?.advertised_price?.fractional),
                                    )}
                                </p>
                            ) : (
                                <a
                                    href={handleOnPressCall()}
                                    className="cyan-button"
                                >
                                    Call for Pricing
                                </a>
                            ) : null}

                        {machineData?.meter_reading ? (
                            <p className="machineData_meter_reading">
                                <b>Machine Hours:</b> {machineData?.meter_reading}
                            </p>
                        ) : (
                            <p className="machineData_Hours">
                                <b>Machine Hours:</b>
                                N/A
                            </p>
                        )}
                    </div>
                    <p className="machineData_location">
                        {machineData?.location?.trim() || machineData?.location}
                    </p>

                    <div className="contact-us-box">
                        <button
                            className="Contact-Us-Btn"
                            onClick={openModal}
                        >
                            Contact Us
                        </button>

                        <button
                            className="Details-Btn"
                            onClick={navigateCard}
                        >
                            Details
                        </button>
                    </div>
                </div>
            </div>


            <Modal open={searchModalIsOpen} onClose={closeModal} center showCloseIcon={false}
            >
                <ContactForm handleInventoryContactMenu={`${machineData.assetAdvertisementId}-${machineData?.year || ''
                    }-${machineData?.manufacturer?.name}-${machineData?.model?.number
                    }`} />
            </Modal>
        </>
    )
}
export default EquipmentCard