import { Why_US_Array, Why_US_String } from "../../../Utils/Constants"
import "./with_us.css"

const WithUs = () => {
    return (
        <>
            <div className="Inventory">
                <div>
                    <div className="Inventory_Heading">
                        {Why_US_String.WHY_US}
                    </div>
                    <div className="Inventory_Sub_Heading">
                        {Why_US_String.Everything_You}
                    </div>
                </div>
                <div className="With_US_Data_Lists">
                    {
                        Why_US_Array.map((item) => {
                            return (
                                <div className="with_us_card">
                                    <div className="with_us_img">
                                        <img src={item.img} alt=""/>
                                    </div>
                                    <div className="with_us_title">
                                        {item.title}
                                    </div>
                                    <div className="with_us_dec">
                                        {item.dec}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default WithUs