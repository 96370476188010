import { useEffect } from "react";
import { Faq_String } from "../../../Utils/Constants"
import "./Faq_banner.css"

const FaqBanner = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrolltotop = document.documentElement.scrollTop || document.body.scrollTop;
            const target = document.getElementById("main1");
            const xvalue = "center";
            const factor = 0.3;
            const yvalue = scrolltotop * factor;
            target.style.backgroundPosition = `${xvalue} -${yvalue}px`;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className="FaqBanner" id="main1">
                <div className="container">
                    <div className="faq_title">
                        {Faq_String.title}
                    </div>
                    <div className="faq_sub_title">
                        {Faq_String.subTitle}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqBanner