import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/layout/layout"
import { ApplicationId } from "../../api/url"
import { useRef, useState } from "react";
import * as Yup from "yup";
import { postContactUs } from "../../services/contactUsService"
import { toast } from "react-toastify";
import { FooterString, ReCAPTCHA_sitekey, contact_us } from "../../Utils/Constants";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import "./contact.css"
import ContactForm from "../../components/Contact-Form";
const Contact = () => {
    const { state } = useLocation()

    const handleInventoryContactMenu = state?.handleInventoryContactMenu
    return (
        <>
            <Layout activeTab="Contact">
                <div className="container">
                    <div className="contact_grid">
                        <div className="contact_left_grid">
                            <div className="contact_title">{contact_us.Contact_US}</div>
                            <div className="contact_sub_title">{contact_us.address_1}</div>
                            <div className="contact_sub_title">{contact_us.address_2}</div>
                            <div className="contact_sub_title">{contact_us.address_3}</div>
                            <div className="contact_sub_title">
                                <a href={`mailto:${contact_us.email}`}>
                                    {contact_us.email}
                                </a>
                            </div>
                            <div className="contact_sub_title">
                                <a href={`tel:${contact_us.number}`}>
                                    {contact_us.number}
                                </a>
                            </div>
                            <div className="contact_sub_video">
                                <iframe src="https://www.facebook.com/v2.4/plugins/page.php?adapt_container_width=true&app_id=304553036307597&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df4a3a6dec319fedfc%26domain%3Dwww.hyellc.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.hyellc.com%252Ff433acb42f3ff7501%26relation%3Dparent.parent&container_width=0&height=160&hide_cover=false&href=http%3A%2F%2Fwww.facebook.com%2Fhyellc&locale=en_US&sdk=joey&show_facepile=false&show_posts=false&width=400" />
                            </div>
                        </div>
                        <div className="Contact_Page_Right_Box">
                            <ContactForm handleInventoryContactMenu={handleInventoryContactMenu}/>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Contact