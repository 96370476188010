import { useEffect } from "react";
import Layout from "../../layout";

const WebsiteTerms = () => {
    useEffect(() => {
        const loadScript = async () => {
            try {
                const script = document.createElement("script");
                script.src = "https://app.termly.io/embed-policy.min.js";
                script.async = true;
                document.body.appendChild(script);
            } catch (error) {
                console.error("Error loading Termly script:", error);
            }
        };

        loadScript();

        return () => {
            
        };
    }, []);
    return (
        <>
            <Layout>
                <div className="container">
                    <div name="termly-embed" data-id="af5de5b5-bc45-43a9-8514-f30dd7b035e5"></div>
                </div>
            </Layout>
        </>
    )
}
export default WebsiteTerms