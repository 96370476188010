import "./banner.css"
import { Dashboard_Strings } from "../../../Utils/Constants"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Banner = () => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrolltotop = document.documentElement.scrollTop || document.body.scrollTop;
    //         const target = document.getElementById("main1");
    //         const xvalue = "center";
    //         const factor = 1;
    //         const yvalue = scrolltotop * factor;
    //         target.style.backgroundPosition = `${xvalue} -${yvalue}px`;
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
    return (
        <>
            <div className="dashboard_banner">
                <div className="dashboard_banner_left">

                    <div className="dashboard_banner_left_Text">
                        <div className="dashboard_text_one">{Dashboard_Strings?.Welcome}</div>
                        <div className="dashboard_text_two">{Dashboard_Strings?.Ultimate_Heavy}</div>
                        <div>
                            <button className="Get_In_Touch_But" onClick={()=>navigate(`/contact`)}>{Dashboard_Strings?.Get_in_Touch}</button>
                        </div>
                    </div>

                </div>
                <div className="dashboard_banner_right" id="main1">

                    <div className="dashboard_banner_right_Text">
                        <div className="dashboard_text_one">{Dashboard_Strings?.Welcome}</div>
                        <div className="dashboard_text_two">{Dashboard_Strings?.Ultimate_Heavy}</div>
                        <div>
                            <button className="Get_In_Touch_But" onClick={()=>navigate(`/contact`)}>{Dashboard_Strings?.Get_in_Touch}</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Banner