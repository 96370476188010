import { useNavigate } from "react-router-dom";
import { ApplicationId } from "../../api/url"
import { useRef, useState } from "react";
import * as Yup from "yup";
import { postContactUs } from "../../services/contactUsService"
import { toast } from "react-toastify";
import { FooterString, ReCAPTCHA_sitekey, contact_us } from "../../Utils/Constants";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({handleInventoryContactMenu}) => {
    const navigate = useNavigate();
    const reCaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const AccSchema = Yup.object().shape({
        firstname: Yup.string().required("Please Enter First Name"),
        lastname: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Enter Valid Email Address")
            .required("Please Enter E-mail Address"),
        message: Yup.string().required("Please Enter message"),
        phone: Yup.string().required("Please Enter Phone Number"),
        reCaptcha: Yup.string().required("recaptcha is a required field"),
    });

    const handleSubmitForm = (value, resetForm) => {
        const htmlForm = `
      <div style="font-size:25px">
        <div>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
            </tr>
            <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
            </tr>
          </table>
        <div>
      </div>
    `;
        const htmlFormWidthInventory = `
        <div style="font-size:25px">
          <div>
            <table>
              <tr style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
            }</a></td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
            }</td>
              </tr>
              <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
                <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
                <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
            }</td>
              </tr>
            </table>
          <div>
        </div>
      `;
        setIsLoading(true);
        let payload = {
            HtmlContent: handleInventoryContactMenu
                ? htmlFormWidthInventory
                : htmlForm,
            ApplicationId: ApplicationId,
        };
        postContactUs(payload)
            .then((res) => {
                reCaptchaRef?.current?.reset();
                setIsLoading(false);
                toast.success("email send successfully");
                resetForm();
            })
            .catch((err) => {
                toast.error(err?.data);
                setIsLoading(false);
            });
    };
    return (
        <>
            <div className="Contact_Page_Right_div">
                {isLoading && (
                    <div className="loader_Box">
                        <div className="loading-spinner"></div>
                    </div>
                )}
                <div>
                    <div className="contact_form_title">
                        Send us a message
                        {/* <img src={ContactUsArrowIcone} alt="" width={"40px"} /> */}
                    </div>

                    <div className="contact_form_sub_title">
                        Click on each box below to enter your information
                    </div>

                    <Formik
                        initialValues={{
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone: "",
                            location: "",
                            message: "",
                            inventory: handleInventoryContactMenu,
                            reCaptcha: "",
                        }}
                        validationSchema={AccSchema}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmitForm(values, resetForm);
                        }}
                    >
                        {({
                            errors,
                            touched,
                            values,
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="contact_grid_box_top">
                                    <div className="Contact_Page_field">
                                        <input
                                            type="text"
                                            id="userfirstname"
                                            name="firstname"
                                            className="contact_page_input"
                                            placeholder="First Name*"
                                            value={values.firstname}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        {errors.firstname && touched.firstname && (
                                            <div
                                                style={{ fontSize: 14, textAlign: "left" }}
                                                className="error_message"
                                            >
                                                {errors.firstname}
                                            </div>
                                        )}
                                    </div>
                                    <div className="Contact_Page_field">
                                        <input
                                            type="text"
                                            id="userfirstname"
                                            placeholder="Last Name*"
                                            name="lastname"
                                            className="contact_page_input"
                                            value={values.lastname}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        {errors.lastname && touched.lastname && (
                                            <div
                                                style={{ fontSize: 14, textAlign: "left" }}
                                                className="error_message"
                                            >
                                                {errors.lastname}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="Contact_Page_field">
                                    <input
                                        type="email"
                                        id="useremail"
                                        placeholder="Email Address*"
                                        name="email"
                                        className="contact_page_input"
                                        value={values.email}
                                        onChange={(e) => handleChange(e)}
                                    />

                                    {errors.email && touched.email && (
                                        <div
                                            style={{ fontSize: 14, textAlign: "left" }}
                                            className="error_message"
                                        >
                                            {errors.email}
                                        </div>
                                    )}
                                </div>
                                <div className="Contact_Page_field">
                                    <input
                                        type="number"
                                        id="userfirstname"
                                        placeholder=" Phone Number*"
                                        name="phone"
                                        className="contact_page_input"
                                        value={values.phone}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.phone && touched.phone && (
                                        <div
                                            style={{ fontSize: 14, textAlign: "left" }}
                                            className="error_message"
                                        >
                                            {errors.phone}
                                        </div>
                                    )}
                                </div>
                                {handleInventoryContactMenu && (
                                    <div className="contact_grid_box_top Contact_Page_field">
                                        <input
                                            type="text"
                                            id="inventory"
                                            name="inventory"
                                            placeholder="Inventory"
                                            className="contact_page_input"
                                            value={values?.inventory}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                )}
                                {/* <div className="contact_grid_box_top Contact_Page_field">
                                                    <label className=" contact-input-label">Location</label>
                                                    <input
                                                        type="text"
                                                        id="location"
                                                        name="location"
                                                        className="contact_page_input"
                                                        value={values.location}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div> */}
                                <div className="contact_grid_box_top Contact_Page_field">
                                    <textarea
                                        rows="5"
                                        type="textarea"
                                        id="message"
                                        name="message"
                                        className="contact_page_input_textarea"
                                        value={values.message}
                                        placeholder="Tell us what you're looking for and how we can help"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.message && touched.message && (
                                        <div
                                            style={{ fontSize: 14, textAlign: "left" }}
                                            className="error_message"
                                        >
                                            {errors.message}
                                        </div>
                                    )}
                                </div>
                                <div className="contact_grid_box_top Contact_Page_field google_recaptch_box">
                                    <ReCAPTCHA
                                        ref={reCaptchaRef}
                                        size="normal"
                                        sitekey={ReCAPTCHA_sitekey}
                                        onChange={(captchaCode) => {
                                            setFieldValue("reCaptcha", captchaCode);
                                            // onReCAPTCHAChange
                                        }}
                                    />
                                    {errors.reCaptcha && touched.reCaptcha && (
                                        <div
                                            style={{ fontSize: 14, textAlign: "left" }}
                                            className="error_message"
                                        >
                                            {errors.reCaptcha}
                                        </div>
                                    )}
                                </div>
                                <div className="button_grid">
                                    <div className="button_grid">
                                        <button type="submit" className="contact_us_button" disabled={isLoading}>
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}
export default ContactForm