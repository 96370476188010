import { useEffect } from "react";
import Layout from "../../layout";

const CookiePolicy = () => {
    useEffect(() => {
        const loadScript = async () => {
            try {
                const script = document.createElement("script");
                script.src = "https://app.termly.io/embed-policy.min.js";
                script.async = true;
                document.body.appendChild(script);
            } catch (error) {
                console.error("Error loading Termly script:", error);
            }
        };

        loadScript();

        return () => {
            // Clean up if needed
        };
    }, []);
    return (
        <>
            <Layout>
                <div className="container">
                    <div name="termly-embed" data-id="d7f9286c-c115-4c7c-8085-dd963aa20087"></div>
                </div>
            </Layout>
        </>
    )
}
export default CookiePolicy