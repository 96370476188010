import { About_US_String } from "../../Utils/Constants"
import Layout from "../../components/layout/layout"
import "./about-us.css"

const AboutUS = () => {
    return (
        <>
            <Layout activeTab="About Us">
                <div className="container">
                    <div className="About_US">
                        <div className="About_US_right">
                            <div className="About_US_Mini_Screen">
                                <div className="About_US_Text">{About_US_String.About_US}</div>
                                <div className="About_US_sub_text">{About_US_String.Our_History}</div>
                                <div className="About_US_sub_text_two">{About_US_String.About_US_String}</div>
                            </div>
                        </div>
                        <div className="About_US_left">
                            <div className="About_US_Big_Screen">
                                <div className="About_US_Text">{About_US_String.About_US}</div>
                                <div className="About_US_sub_text">{About_US_String.Our_History}</div>
                                <div className="About_US_sub_text_two">{About_US_String.About_US_String}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default AboutUS