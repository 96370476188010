import { contact_us } from "../../../Utils/Constants"
import Fb_Logo from "../../../assets/img/FB.png"
import "./Contact_Us.css"

const ContactUs = () => {
    return (
        <>
            <div className="ContactUs">
                <div className="ContactUs_left">
                    <div className="ContactUs_Text">{contact_us.Contact_US}</div>
                    <div className="ContactUs_Address">{contact_us.address_1}</div>
                    <div className="ContactUs_Address">{contact_us.address_2}</div>
                    <div className="ContactUs_Address">{contact_us.address_3}</div>
                    <div className="ContactUs_Address">
                        <a href={`mailto:${contact_us.email}`}>
                            {contact_us.email}
                        </a>
                    </div>
                    <div className="ContactUs_Address">
                        <a href={`tel:${contact_us.number}`}>
                            {contact_us.number}
                        </a>
                    </div>
                    <div className="ContactUs_Address">
                        <a href={`${contact_us.fb}`} target="_blank">
                            <img src={Fb_Logo} alt="fb"/>
                        </a>
                    </div>
                </div>
                <div className="ContactUs_right"></div>
            </div>
        </>
    )
}
export default ContactUs